import { useParams } from 'react-router-dom';

export default function User() {
    const { id } = useParams();

    return (
        <div>
            <h1>Page utilisateur</h1>
            <p>ID utilisateur : {id}</p>
        </div>
    );
}