import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import Home from './pages/Home';
import About from './pages/About';
import User from './pages/User';
import Login from './pages/Login';
import Header from './components/Header';
import Footer from './components/Footer';
import './styles/App.css';

function App() {
  const isLoggedIn = false; // état de connexion, peut être défini à true ou false en fonction de l'état de connexion de l'utilisateur

  return (
    // Initialisez le composant Router pour gérer les routes dans l'application
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/user/:id" element={<User />} />
        <Route
          path="/login"
          element={isLoggedIn ? <Navigate to="/" /> : <Login />}
        />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;